import React from 'react'
import { useIntercom } from 'react-use-intercom'

import styles from './MaintenancePage.module.scss'

import CustomButton from 'components/elements/buttons/CustomButton'
import MainHeader from 'components/navigation/MainHeader'
import PromoBlock from '../../components/elements/blocks/PromoBlock'

const MaintenancePage: React.FC = () => {
  const crumbsItems = [
    {
      label: 'Maintenance',
      isActive: true,
    },
  ]

  const { show } = useIntercom()

  const handleRefresh = () => {
    window.location.reload()
  }

  const handleSupport = () => {
    show()
  }

  return (
    <div className={styles.container}>
      <MainHeader crumbsItems={crumbsItems} reducedDisplayMode />

      <PromoBlock
        title={'Maintenance in progress'}
        image={`${process.env.PUBLIC_URL}/maintenance.png`}
        className={styles.notice}
      >
        We’re busy improving SOAX to make your experience even better. During
        this time, a few features might not be available. Thanks for bearing
        with us — we’ll be back up shortly! If the issue persists, contact{' '}
        <span onClick={handleSupport} className={styles.supportLink}>
          support chat
        </span>{' '}
        .
      </PromoBlock>

      <CustomButton onClick={handleRefresh}>Refresh page</CustomButton>
    </div>
  )
}

export default MaintenancePage

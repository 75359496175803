import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import styles from './PricingPage.module.scss'

import PricingContent from 'components/modules/pricing/PricingContent'
import { Server as ServerIcon, Xml as ScrapingIcon } from 'components/icons'
import { PricingActionsTypes } from 'store/pricing/actions'

const products = [
  {
    label: 'Scraping APIs: SERP, Social, eCommerce, Unblocker',
    icon: <ScrapingIcon />,
  },
  {
    label: 'Proxies: Residential, Mobile, ISP, DC',
    icon: <ServerIcon />,
  },
]

const PricingPage: React.FC = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch({ type: PricingActionsTypes.PRICING_GET_PLANS })
  }, [dispatch])

  const productsList = products.map((product, index) => (
    <div className={styles.productItem} key={`pricing-products-item_${index}`}>
      <div className={styles.productItemIconWrapper}>{product.icon}</div>
      {product.label}
    </div>
  ))

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h2 className={styles.title}>All SOAX products in 1 subscription</h2>

        <div className={styles.productsList}>{productsList}</div>
      </div>

      <PricingContent />
    </div>
  )
}

export default PricingPage

import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import styles from './SubscriptionsInfo.module.scss'

import TitledCard from 'components/elements/blocks/TitledCard'
import CustomButton from 'components/elements/buttons/CustomButton'
import PlanStatus from 'components/elements/blocks/PlanStatus'
import TopUpBalanceModal from 'components/modules/pricing/TopUpBalanceModal'

import { selectBalanceInfo, selectSubscriptionInfo } from 'store/user/selectors'
import {
  selectSubscriptionProcessing,
  selectTopUpModalVisibility,
} from 'store/pricing/selectors'
import { PricingActionsTypes } from 'store/pricing/actions'
import { getNormalizedPrice } from 'utils/functions'
import { IUserSubscription } from 'models/user'
import { logAmplitudeEvent } from 'utils/analytics'

interface ISubscriptionInfo {
  reducedDisplayMode?: boolean
}

const SubscriptionInfo: React.FC<ISubscriptionInfo> = (props) => {
  const { reducedDisplayMode } = props

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const balanceInfo = useSelector(selectBalanceInfo)
  const subscriptionInfo: IUserSubscription = useSelector(
    selectSubscriptionInfo
  )
  const isModalShown = useSelector(selectTopUpModalVisibility)
  const subscriptionProcessing = useSelector(selectSubscriptionProcessing)

  const [activeTopUpTabIndex, setActiveTopUpTabIndex] = useState(0)

  const balanceData = !!balanceInfo
    ? {
        balance: getNormalizedPrice(balanceInfo.balance),
        accrued: getNormalizedPrice(balanceInfo.accrued),
        creditSum: getNormalizedPrice(balanceInfo.creditSum),
      }
    : {
        balance: 'n/a',
        accrued: 'n/a',
        creditSum: 'n/a',
      }

  const handleCloseModal = () => {
    dispatch({
      type: PricingActionsTypes.PRICING_TOGGLE_TOP_UP_MODAL,
      isTopUpModalShown: false,
    })

    setActiveTopUpTabIndex(0)
  }

  const handleShowModal = () => {
    dispatch({
      type: PricingActionsTypes.PRICING_TOGGLE_TOP_UP_MODAL,
      isTopUpModalShown: true,
    })
  }

  /*const chartOptions = {
    cutout: '80%',
    rotation: 0,
    plugins: {
      legend: {
        display: false,
      },
    },
    tooltip: false,
    responsive: true,
    maintainAspectRatio: false,
  }

  const chartData = {
    datasets: [
      {
        data: [balanceData.used, balanceData.remain],
        backgroundColor: ['#E9EAEC', '#26BD6C'],
        borderWidth: 0,
      },
    ],
  }

  const emptyChartData = {
    datasets: [
      {
        data: [100],
        backgroundColor: ['#E9EAEC'],
        borderWidth: 0,
      },
    ],
  }*/

  const handleGoToPricingClick = () => navigate('/pricing')

  const handleChoosePlanClick = () => {
    logAmplitudeEvent('choose plan')
    navigate('/pricing')
  }

  const handleActivateTrial = () => {
    const subscriptionData = {
      plan_id: subscriptionInfo.full_plan_id,
    }

    dispatch({
      type: PricingActionsTypes.PRICING_GET_INVOICE,
      subscriptionData,
    })
  }

  const handleTopUpTabChange = (index: number) => {
    setActiveTopUpTabIndex(index)
  }

  const handleManageTopUp = () => {
    if (isTopUpActive) {
      setActiveTopUpTabIndex(1)
      handleShowModal()
    } else {
      return null
    }
  }

  const isTopUpActive =
    subscriptionInfo?.status === 'trial' ||
    subscriptionInfo?.status === 'active' ||
    subscriptionInfo?.status === 'paused'

  /*const topUpBtnColor =
    subscriptionInfo?.status === 'active' ? 'outline' : 'blue'*/

  const renderBtn = () => {
    if (subscriptionInfo?.status === 'trial') {
      return (
        <CustomButton
          onClick={handleActivateTrial}
          loading={subscriptionProcessing}
        >
          Activate full plan now
        </CustomButton>
      )
    } /*else if (subscriptionInfo?.status === 'active') {
      return (
        <CustomButton onClick={handleGoToPricingClick}>
          Change plan
        </CustomButton>
      )
    }*/ else if (
      !subscriptionInfo?.status ||
      subscriptionInfo?.status === 'expired' ||
      subscriptionInfo?.status === 'cancelled'
    ) {
      return (
        <CustomButton onClick={handleChoosePlanClick}>Choose plan</CustomButton>
      )
    } else if (
      !subscriptionInfo?.status ||
      subscriptionInfo?.status === 'paused' ||
      subscriptionInfo?.status === 'active' ||
      subscriptionInfo?.status === 'unpaid'
    ) {
      return (
        <CustomButton color={'outline'} onClick={handleGoToPricingClick}>
          Change plan
        </CustomButton>
      )
    } else {
      return null
    }
  }

  const messagesList = !!subscriptionInfo?.sub_statuses?.length
    ? subscriptionInfo.sub_statuses?.map((message, index) => (
        <div className={styles.message} key={`subscription-message_${index}`}>
          <p>{message.title}</p>
          {/*{!!message.url && (
            <p>
              <a
                href={message.url}
                target={'_blank'}
                rel={'noopener noreferrer'}
                className={styles.messageLink}
              >
                click here for more details
              </a>
            </p>
          )}*/}
        </div>
      ))
    : null

  const handleMessageUrlClick = (message: { title: string; url?: string }) => {
    if (!!message.url) {
      logAmplitudeEvent('click Pay invoice')

      window.open(message.url, '_blank', 'noopener,noreferrer')
    }
  }

  const renderMessagesBtns = () => {
    if (!!subscriptionInfo?.sub_statuses?.length) {
      return subscriptionInfo.sub_statuses?.map((message, index) => {
        if (message.url) {
          return (
            <CustomButton
              color={'blue'}
              className={styles.invoiceBtn}
              key={`issued_invoice_btn-${index}`}
              onClick={() => handleMessageUrlClick(message)}
            >
              Pay invoice
            </CustomButton>
          )
        } else {
          return null
        }
      })
    }
  }

  const renderMessages = () => {
    if (!!subscriptionInfo?.sub_statuses?.length) {
      return <div className={styles.messagesList}>{messagesList}</div>
    } else {
      return null
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <TitledCard title={'Your subscription plan'} className={styles.card}>
          <div className={styles.cardContent}>
            <div className={styles.planInfo}>
              <div className={styles.planName}>
                {subscriptionInfo?.status
                  ? subscriptionInfo.plan
                  : 'No active subscription'}
              </div>

              {subscriptionInfo?.status && (
                <PlanStatus
                  status={subscriptionInfo?.status}
                  className={styles.planStatus}
                />
              )}
            </div>

            {renderMessages()}

            <div className={styles.btnsBlock}>
              {renderMessagesBtns()}

              {renderBtn()}
            </div>

            {subscriptionInfo?.status === 'blocked' && (
              <a href={'/'} className={styles.contactLink}>
                Please contact support
              </a>
            )}
          </div>
        </TitledCard>

        {!reducedDisplayMode && (
          <TitledCard title={'Balance'} className={styles.card}>
            <div className={styles.cardContent}>
              {/*<div className={styles.balanceInfo}>
                  Spent this month ${balanceData.accrued}
                </div>*/}
              {/*{!!balanceData && balanceData.total > 0 ? (
                <div className={styles.balanceInfo}>
                  Used ${balanceData.used} of ${balanceData.total}
                </div>
              ) : (
                <div className={styles.balanceInfo}>Used $0 of $0</div>
              )}*/}

              <div className={styles.rowBlock}>
                <div className={styles.balanceMain}>${balanceData.balance}</div>

                <div className={styles.topUpBlock}>
                  {!!subscriptionInfo?.top_up_params ? (
                    <div
                      className={`
                      ${styles.activeTopUpStatus}
                      ${isTopUpActive ? styles.clickable : ''}
                    `}
                      onClick={handleManageTopUp}
                    >
                      Auto top up ON
                    </div>
                  ) : (
                    <div
                      className={`
                      ${styles.inactiveTopUpStatus}
                      ${isTopUpActive ? styles.clickable : ''}
                    `}
                      onClick={handleManageTopUp}
                    >
                      Auto top up OFF
                    </div>
                  )}
                </div>
              </div>

              <CustomButton
                onClick={handleShowModal}
                disabled={!isTopUpActive}
                color={'outline'}
                className={styles.topUpBtn}
              >
                Top up balance
              </CustomButton>
            </div>
          </TitledCard>
        )}
      </div>

      <TopUpBalanceModal
        isModalShown={isModalShown}
        activeTopUpTabIndex={activeTopUpTabIndex}
        onActiveTopUpTabIndex={handleTopUpTabChange}
        onModalClose={handleCloseModal}
      />
    </div>
  )
}

export default SubscriptionInfo

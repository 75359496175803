import React, { useState } from 'react'
import { Form, Slot } from '@slashid/react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'

import {
  CheckAlt,
  Logotype,
  ReviewG2,
  ReviewCapterra,
  /*LightningAlt as LightningIcon,*/
} from 'components/icons'

import styles from '../AuthPage.module.scss'

import CustomInput from 'components/elements/inputs/CustomInput'
import CustomButton from 'components/elements/buttons/CustomButton'
import MaintenanceBlock from 'components/elements/blocks/MaintenanceBlock'

import { logAmplitudeEvent } from 'utils/analytics'
import { selectMaintenanceModeStatus } from 'store/service/selectors'

const tools = [
  'Proxies: residential, mobile, ISP, datacenter',
  'Scraping APIs (now available in beta)',
]

const SignInPage = () => {
  // @ts-ignore
  const dataLayer = window.dataLayer

  const isMaintenanceMode = useSelector(selectMaintenanceModeStatus)

  const handleFormSuccess = (user: any) => {
    const lastAuthMethod = user.authentications[0].method

    logAmplitudeEvent('login success', {
      form: 'sign_in',
      authMethod: lastAuthMethod === 'otp_via_email' ? 'email' : 'google',
    })

    if (user.firstLogin) {
      dataLayer.push({ event: 'signup_completed' })
    } else {
      dataLayer.push({ event: 'login_completed' })
    }
  }

  const handleFormError = () => {
    logAmplitudeEvent('login fail', {
      form: 'sign_in',
    })
  }

  const toolsList = tools.map((tool, index) => (
    <li className={styles.tool} key={`tool-item_${index}`}>
      <div className={styles.toolIconWrapper}>
        <CheckAlt />
      </div>

      <div className={styles.toolName}>{tool}</div>
    </li>
  ))

  return (
    <div className={styles.wrapper}>
      <Helmet>
        <title>Sign In</title>
        <meta name="description" content="Sign in to your SOAX account" />
      </Helmet>

      <div className={styles.leftBlock}>
        <a href={'https://soax.com/'} className={styles.logoWrapper}>
          <Logotype />
        </a>

        <div className={styles.description}>
          Log in to your account to access all our products, including:
        </div>

        <ul className={styles.tools}>{toolsList}</ul>

        {/*<div
          className={styles.promoBlock}
          style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/fire.png)` }}
        >
          <div className={styles.promoHeader}>
            <div className={styles.promoIcon}>
              <LightningIcon />
            </div>
            <div className={styles.promoTitle}>
              Black Friday surprise just for you
            </div>
          </div>

          <div className={styles.promoDesc}>
            Sign in to discover your personalized Black Friday offer!
          </div>
        </div>*/}

        <div className={styles.reviews}>
          <div className={styles.reviewItem}>
            <ReviewG2 />
          </div>
          <div className={styles.reviewItem}>
            <ReviewCapterra />
          </div>
        </div>
      </div>
      <div className={styles.rightBlock}>
        <div className={styles.formWrapper}>
          <div className={styles.formBackground1} />
          <div className={styles.formBackground2} />

          {isMaintenanceMode ? (
            <div className={styles.formContainer}>
              <MaintenanceBlock />
            </div>
          ) : (
            <div className={styles.formContainer}>
              <h1 className={styles.title}>Log in to your account</h1>

              <div className={styles.form}>
                <Form onSuccess={handleFormSuccess} onError={handleFormError}>
                  <Slot name="initial">
                    <Form.Initial.SSO />

                    <div className={styles.separator}>
                      <div className={styles.separatorLine} />
                      OR
                      <div className={styles.separatorLine} />
                    </div>

                    {/*<Form.Initial.Controls>
                    <Form.Initial.Controls.Input />
                    <Form.Initial.Controls.Submit />
                  </Form.Initial.Controls>*/}

                    <Form.Initial.Controls>
                      {({ handleSubmit, factors, handleTypes }) => {
                        return (
                          <CustomForm
                            handleSubmit={handleSubmit}
                            factors={factors}
                            handleTypes={handleTypes}
                          />
                        )
                      }}
                    </Form.Initial.Controls>
                  </Slot>

                  <Slot name="footer"> </Slot>
                </Form>
              </div>

              <div className={styles.loginBlock}>
                Don't have an account?{' '}
                <Link to={'/sign-up'} className={styles.loginLink}>
                  Sign up
                </Link>
              </div>
            </div>
          )}
        </div>

        <div className={styles.bottomNotice}>
          This site is protected by reCAPTCHA and the Google{' '}
          <a
            href="https://policies.google.com/privacy"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </a>{' '}
          and{' '}
          <a
            href="https://policies.google.com/terms"
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms of Service
          </a>{' '}
          apply
        </div>
      </div>
    </div>
  )
}

interface ICustomFormProps {
  handleSubmit: any
  factors: any
  handleTypes: any
}

const CustomForm: React.FC<ICustomFormProps> = (props) => {
  const { handleSubmit } = props

  const [email, setEmail] = useState('')

  const handleContinueClick = (e: any) => {
    e.preventDefault()

    logAmplitudeEvent('login clicked', {
      form: 'sign_in',
      authMethod: 'email_and_code',
    })

    // we only wanted to use the email_link method, otherwise we could have used the handleTypes and the factors to determine the input type
    handleSubmit(
      { method: 'otp_via_email' },
      { type: 'email_address', value: email }
    )
  }

  return (
    <form onSubmit={handleContinueClick} id={'sign_in-form'}>
      <div className={styles.inputs}>
        <CustomInput
          className={styles.input}
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
          inputSize={'l'}
        />
      </div>

      <CustomButton
        type={'submit'}
        wide
        largeRadius
        size={'l'}
        disabled={!email}
      >
        Continue
      </CustomButton>
    </form>
  )
}

export default SignInPage

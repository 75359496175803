import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import styles from '../ProxyContent/ProxyContent.module.scss'

import { ISelectOption } from 'components/elements/inputs/CustomSelect/CustomSelect'
import ProxyListConfiguration from './ProxyListConfiguration'
import ProxyListResult from './ProxyListResult'
import {
  Apps as DCIcon,
  MobilePhone as MobileIcon,
  Server as ISPIcon,
  Wifi as WifiIcon,
} from '../../../icons'
import Flag from 'components/elements/other/Flag'

import { selectProxyPackages, selectAvailableHosts } from 'store/user/selectors'
import {
  randomCity,
  randomCountry,
  randomIsp,
  randomRegion,
} from 'utils/constants'
import { IProxyPackage } from 'models/user'
import { getDeepTargetResult } from 'utils/functions'
import { ServiceActionsTypes } from 'store/service/actions'
import {
  getAvailableSessionIntervals,
  getProxyResultString,
} from 'utils/proxy/proxyFunctions'
import {
  dcSessionDefaultInterval,
  ispSessionDefaultInterval,
  mobileSessionDefaultInterval,
  residentialSessionDefaultInterval,
  proxyListResults,
  defaultOutputMaskOption,
  defaultSpaceSymbol,
  defaultHostValue,
  defaultAmpersandSymbol,
} from 'utils/proxy/proxyConstants'
// import { PricingActionsTypes } from 'store/pricing/actions'

const proxyTypesOptions = [
  {
    label: 'Residential proxies',
    shortLabel: 'Residential',
    value: 'wifi',
    icon: <WifiIcon />,
  },
  {
    label: 'Mobile proxies',
    shortLabel: 'Mobile',
    value: 'mobile',
    icon: <MobileIcon />,
  },
  {
    label: 'Datacenter proxies',
    shortLabel: 'DC',
    value: 'dc',
    icon: <DCIcon />,
  },
  {
    label: 'ISP proxies',
    shortLabel: 'ISP',
    value: 'isp',
    icon: <ISPIcon />,
  },
]

interface IProxyListGeneratorProps {
  activeProxySectionIndex: number
}

const ProxyListGenerator: React.FC<IProxyListGeneratorProps> = ({
  activeProxySectionIndex,
}) => {
  const dispatch = useDispatch()
  const proxyPackages = useSelector(selectProxyPackages)
  const availableHosts = useSelector(selectAvailableHosts)

  const [selectedProxyType, setProxyType] = useState<ISelectOption>(
    proxyTypesOptions[0]
  )
  const [selectedCountry, setCountry] = useState<ISelectOption>(randomCountry)
  const [selectedRegion, setRegion] = useState<ISelectOption>(randomRegion)
  const [selectedCity, setCity] = useState<ISelectOption>(randomCity)
  const [selectedIsp, setIsp] = useState<ISelectOption>(randomIsp)
  const [selectedTimeInterval, setTimeInterval] = useState<ISelectOption>({
    label: '300 secs',
    value: '300',
  })
  const [customTimeInterval, setCustomTimeInterval] = useState('')
  const [isBindIPActive, toggleBindIPActive] = useState(false)
  const [activeSessionLengthType, setSessionLengthType] = useState<
    'same' | 'new'
  >('same')
  const [sessionPrefix, setSessionPrefix] = useState('number01')
  const [connectionsAmount, setConnectionsAmount] = useState(10)
  // const [listResult, setListResult] = useState<string[] | null>(null)
  // const [wasInitiallyGenerated, toggleInitiallyGenerated] = useState(false)
  const [selectedOutputMask, setOutputMask] = useState(defaultOutputMaskOption)
  const [spaceSymbol, setSpaceSymbol] = useState(defaultSpaceSymbol)
  const [ampersandSymbol, setAmpersandSymbol] = useState(defaultAmpersandSymbol)
  const [selectedHostValue, setHostValue] = useState(defaultHostValue)

  const getSelectedProxyPackage = () => {
    const selectedTypePackagesArray = proxyPackages.filter(
      (proxyPackage: IProxyPackage) => {
        return proxyPackage.connection_type === selectedProxyType.value
      }
    )

    return selectedTypePackagesArray[0]
  }

  const selectedProxyPackage = getSelectedProxyPackage()

  const availableSessionIntervals = getAvailableSessionIntervals(
    selectedProxyType.value
  )

  const deepTargetResult = getDeepTargetResult(
    spaceSymbol,
    ampersandSymbol,
    selectedRegion.value,
    selectedCity.value,
    selectedIsp.value
  )

  const timeInterval =
    selectedTimeInterval.value === 'custom'
      ? customTimeInterval
      : selectedTimeInterval.value

  const getListResult = useCallback(() => {
    let listResult: string[] = []

    for (let i = 1; i <= connectionsAmount; i++) {
      listResult.push(
        getProxyResultString(
          // @ts-ignore
          proxyListResults[selectedOutputMask.value],
          selectedProxyPackage.id,
          selectedProxyPackage.package_key,
          selectedCountry.value,
          deepTargetResult,
          activeSessionLengthType,
          timeInterval,
          isBindIPActive,
          `${sessionPrefix}_x${i}`,
          selectedHostValue
        )
      )
    }

    return listResult
  }, [
    activeSessionLengthType,
    connectionsAmount,
    deepTargetResult,
    isBindIPActive,
    selectedCountry.value,
    selectedHostValue,
    selectedOutputMask.value,
    selectedProxyPackage.id,
    selectedProxyPackage.package_key,
    sessionPrefix,
    timeInterval,
  ])

  const listResult = getListResult()

  const handleProxyTypeChange = (option: any) => {
    setProxyType(option)

    handleCountryChange({
      label: 'United States',
      value: 'us',
      icon: <Flag countryCode={'us'} className={styles.optionFlag} />,
    })

    handleCustomTimeIntervalChange('')

    handleRegionChange(randomRegion, false)
    handleCityChange(randomCity, false)
    handleIspChange(randomIsp)

    /*dispatch({
      type: PricingActionsTypes.PRICING_GET_PRODUCT_PRICE,
      productIdOrb: selectedProxyType.value,
    })*/
  }

  useEffect(() => {
    handleCountryChange({
      label: 'United States',
      value: 'us',
      icon: <Flag countryCode={'us'} className={styles.optionFlag} />,
    })

    setHostValue(availableHosts.domain)

    /*dispatch({
      type: PricingActionsTypes.PRICING_GET_PRODUCT_PRICE,
      productIdOrb: proxyTypesOptions[0].value,
    })*/
  }, [])

  const handleCountryChange = (option: any) => {
    setCountry(option)

    handleRegionChange(randomRegion, false)
    handleCityChange(randomCity, false)
    handleIspChange(randomIsp)

    dispatch({
      type: ServiceActionsTypes.SERVICE_GET_LOCATION_TARGETS,
      data: { country: option.value },
    })
  }

  const handleRegionChange = (option: any, shouldRequest: boolean = true) => {
    setRegion(option)

    handleCityChange(randomCity, false)
    handleIspChange(randomIsp)

    if (shouldRequest) {
      dispatch({
        type: ServiceActionsTypes.SERVICE_GET_LOCATION_TARGETS,
        data: {
          country: selectedCountry.value,
          region: option.value !== 'any' ? option.value : undefined,
        },
      })
    }
  }

  const handleCityChange = (option: any, shouldRequest: boolean = true) => {
    setCity(option)

    handleIspChange(randomIsp)

    if (shouldRequest) {
      dispatch({
        type: ServiceActionsTypes.SERVICE_GET_LOCATION_TARGETS,
        data: {
          country: selectedCountry.value,
          region:
            selectedRegion.value !== 'any' ? selectedRegion.value : undefined,
          city: option.value !== 'any' ? option.value : undefined,
        },
      })
    }
  }

  const handleIspChange = (option: any) => {
    setIsp(option)
  }

  const handleDeepTargetsReset = () => {
    handleRegionChange(randomRegion, true)
  }

  useEffect(() => {
    handleCountryChange({
      label: 'United States',
      value: 'us',
      icon: <Flag countryCode={'us'} className={styles.optionFlag} />,
    })
  }, [activeProxySectionIndex])

  const handleTimeIntervalChange = (option: any) => {
    setTimeInterval(option)
  }

  const handleCustomTimeIntervalChange = (value: string) => {
    setCustomTimeInterval(value)
  }

  const handleBindIP = () => {
    toggleBindIPActive(!isBindIPActive)
  }

  const handleActiveSessionLengthChange = (value: 'new' | 'same') => {
    setSessionLengthType(value)
  }

  const handleSessionPrefixChange = (e: any) => {
    setSessionPrefix(e.target.value)
  }

  const handleConnectionsAmountChange = (val: any) => {
    setConnectionsAmount(val)
  }

  /*const handleGenerate = () => {
    const result = getListResult()

    setListResult(result)
  }*/

  const handleOutputMaskChange = (option: any) => {
    setOutputMask(option)
  }

  const handleSpaceSymbolChange = (value: string) => {
    setSpaceSymbol(value)
  }

  const handleAmpersandSymbolChange = (value: string) => {
    setAmpersandSymbol(value)
  }

  const handleHostValueChange = (value: string) => {
    setHostValue(value)
  }

  /*useEffect(() => {
    if (selectedCountry.value !== 'any' && !wasInitiallyGenerated) {
      handleGenerate()
      toggleInitiallyGenerated(true)
    }
  }, [wasInitiallyGenerated, selectedCountry.value, handleGenerate])*/

  useEffect(() => {
    if (selectedProxyType.value === 'wifi') {
      handleTimeIntervalChange(residentialSessionDefaultInterval)
    } else if (selectedProxyType.value === 'mobile') {
      handleTimeIntervalChange(mobileSessionDefaultInterval)
    } else if (selectedProxyType.value === 'dc') {
      handleTimeIntervalChange(dcSessionDefaultInterval)
    } else if (selectedProxyType.value === 'isp') {
      handleTimeIntervalChange(ispSessionDefaultInterval)
    }
  }, [selectedProxyType.value])

  return (
    <div className={styles.container}>
      <div
        className={`
          ${styles.contentBlock}
          ${styles.leftSide}
        `}
      >
        <ProxyListConfiguration
          selectedProxyType={selectedProxyType}
          selectedTimeInterval={selectedTimeInterval}
          customTimeInterval={customTimeInterval}
          selectedCountry={selectedCountry}
          selectedRegion={selectedRegion}
          selectedCity={selectedCity}
          selectedIsp={selectedIsp}
          isBindIPActive={isBindIPActive}
          activeSessionLengthType={activeSessionLengthType}
          sessionPrefix={sessionPrefix}
          proxyTypesOptions={proxyTypesOptions}
          availableSessionIntervals={availableSessionIntervals}
          // connectionsAmount={connectionsAmount}
          onProxyTypeChange={handleProxyTypeChange}
          onTimeIntervalChange={handleTimeIntervalChange}
          onCustomTimeIntervalChange={handleCustomTimeIntervalChange}
          onBindIp={handleBindIP}
          onActiveSessionLengthChange={handleActiveSessionLengthChange}
          onSessionPrefixChange={handleSessionPrefixChange}
          onCountryChange={handleCountryChange}
          onRegionChange={handleRegionChange}
          onCityChange={handleCityChange}
          onIspChange={handleIspChange}
          onDeepTargetsReset={handleDeepTargetsReset}
          // onConnectionsAmountChange={handleConnectionsAmountChange}
          // onGenerate={handleGenerate}
        />
      </div>

      <div
        className={`
          ${styles.contentBlock}
          ${styles.rightSide}
        `}
      >
        <ProxyListResult
          selectedProxyType={selectedProxyType}
          selectedOutputMask={selectedOutputMask}
          selectedSpaceSymbol={spaceSymbol}
          selectedAmpersandSymbol={ampersandSymbol}
          selectedHostValue={selectedHostValue}
          resultArray={listResult}
          connectionsAmount={connectionsAmount}
          onOutputMaskChange={handleOutputMaskChange}
          onSpaceSymbolChange={handleSpaceSymbolChange}
          onAmpersandSymbolChange={handleAmpersandSymbolChange}
          onHostValueChange={handleHostValueChange}
          onConnectionsAmountChange={handleConnectionsAmountChange}
        />
      </div>
    </div>
  )
}

export default ProxyListGenerator

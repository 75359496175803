import React, { useState, useCallback, useEffect } from 'react'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import { useIntercom } from 'react-use-intercom'
import { useSlashID } from '@slashid/react'
import { useSelector } from 'react-redux'

/*import { logAmplitudeEvent } from 'utils/analytics'*/

import { resetToken } from 'utils/request'
import { selectUserInitStatus } from 'store/user/selectors'
import { selectMaintenanceModeStatus } from 'store/service/selectors'

import {
  ChartPie as ChartIcon,
  Home as HomeIcon,
  Xml as XmlIcon,
  Server as ServerIcon,
  // Unlock as UnlockIcon,
  Chat as ChatIcon,
  Logout as LogoutIcon,
  Settings as SettingsIcon,
  BookOpen as BookIcon,
} from 'components/icons'

import MainLayout from 'components/layouts/MainLayout/MainLayout'

import HomePage from 'pages/HomePage'
import ScrapingPage from 'pages/products/ScrapingPage'
import ProxyPage from 'pages/products/ProxyPage'
// import UnblockerPage from 'pages/products/UnblockerPage/UnblockerPage'
import PricingPage from 'pages/PricingPage'
import SettingsPage from 'pages/SettingsPage'
import StatisticsPage from 'pages/StatisticsPage'
import UserInitFailedPage from 'pages/UserInitFailedPage'
import MaintenancePage from 'pages/MaintenancePage'
import { resetAmplitude } from 'utils/analytics'

const AppRoutes = () => {
  const { show, update } = useIntercom()
  const { logOut } = useSlashID()

  const isUserInitFailed = useSelector(selectUserInitStatus)
  const isMaintenanceMode = useSelector(selectMaintenanceModeStatus)

  useEffect(() => {
    document.title = 'SOAX'
  }, [])

  const [isMobileMenuOpen, toggleMobileMenu] = useState(false)

  const handleMobileMenuToggle = useCallback(
    () => toggleMobileMenu(!isMobileMenuOpen),
    [isMobileMenuOpen]
  )

  const handleShowIntercom = useCallback(() => {
    toggleMobileMenu(false)
    show()
  }, [show])

  useEffect(() => {
    update({
      customAttributes: {
        'Dashboard Version': 'Dashboard v2 New',
      },
    })
  }, [])

  const handleHelpClick = () => {
    /*logAmplitudeEvent('help center clicked', {
      'help center': 'intercom',
      source: 'dashboard',
    })*/

    window.open(
      'https://helpcenter.soax.com/en',
      '_blank',
      'noopener,noreferrer'
    )
  }

  const handleLogout = () => {
    resetToken()

    logOut()

    resetAmplitude()
    resetToken()

    window.location.reload()
  }

  const navSections = [
    {
      items: [
        {
          label: 'Overview',
          icon: <HomeIcon />,
          to: '/',
          /*items: [
            {
              label: 'Scraping APIs',
              to: '/scraping',
              icon: <XmlIcon />,
            },
            {
              label: 'Proxies',
              to: '/proxy',
              icon: <ServerIcon />,
            },
            /!*{
              label: 'Unblocker',
              to: '/unblocker',
              icon: <UnlockIcon />,
            },*!/
          ],*/
        },
        {
          label: 'Proxies',
          to: '/proxy',
          icon: <ServerIcon />,
        },
        {
          label: 'Scraping APIs',
          to: '/scraping',
          icon: <XmlIcon />,
        },
        /*{
          label: 'Unblocker',
          to: '/unblocker',
          icon: <UnlockIcon />,
        },*/
        {
          label: 'Statistics',
          to: '/statistics',
          icon: <ChartIcon />,
        },
      ],
    },
    {
      items: [
        {
          label: 'Settings',
          to: '/settings',
          icon: <SettingsIcon />,
        },
        {
          label: 'Support',
          onClick: () => handleShowIntercom(),
          icon: <ChatIcon />,
        },
        {
          label: 'Help',
          onClick: () => handleHelpClick(),
          icon: <BookIcon />,
        },
        {
          label: 'Sign out',
          onClick: () => handleLogout(),
          icon: <LogoutIcon />,
        },
      ],
    },
  ]

  if (isMaintenanceMode) {
    return (
      <BrowserRouter>
        <MainLayout
          navSections={navSections}
          isMobileMenuOpen={isMobileMenuOpen}
          onToggleMobileMenu={handleMobileMenuToggle}
        >
          <MaintenancePage />
        </MainLayout>
      </BrowserRouter>
    )
  }

  if (isUserInitFailed) {
    return (
      <BrowserRouter>
        <MainLayout
          navSections={navSections}
          isMobileMenuOpen={isMobileMenuOpen}
          onToggleMobileMenu={handleMobileMenuToggle}
        >
          <UserInitFailedPage />
        </MainLayout>
      </BrowserRouter>
    )
  }

  return (
    <BrowserRouter>
      <MainLayout
        navSections={navSections}
        isMobileMenuOpen={isMobileMenuOpen}
        onToggleMobileMenu={handleMobileMenuToggle}
      >
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/scraping" element={<ScrapingPage />} />
          <Route path="/proxy" element={<ProxyPage />} />
          {/*<Route path="/unblocker" element={<UnblockerPage />} />*/}
          <Route path="/pricing" element={<PricingPage />} />
          <Route path="/settings" element={<SettingsPage />} />
          <Route path="/statistics" element={<StatisticsPage />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </MainLayout>
    </BrowserRouter>
  )
}

export default AppRoutes

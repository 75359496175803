import React from 'react'

import styles from './MaintenanceBlock.module.scss'

const MaintenanceBlock: React.FC = () => {
  return (
    <div className={styles.maintenance}>
      <div
        className={styles.maintenanceImg}
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/maintenance.png)`,
        }}
      />
      <h1 className={styles.maintenanceTitle}>We’ll be back soon</h1>

      <p className={styles.maintenanceDesc}>
        There’s a temporary issue affecting account access. Don’t worry — our
        team is on it and working hard to get things back to normal. Please try
        again later.
      </p>
    </div>
  )
}

export default MaintenanceBlock

import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  postcodeValidator,
  postcodeValidatorExistsForCountry,
} from 'postcode-validator'

import styles from './BillingFormModal.module.scss'

import CustomModal from 'components/elements/modal/CustomModal'
import InputGroup from 'components/elements/forms/InputGroup'
import CustomInput from 'components/elements/inputs/CustomInput'
import CustomButton from 'components/elements/buttons/CustomButton'
import { ISelectOption } from 'components/elements/inputs/CustomSelect/CustomSelect'
import Flag from 'components/elements/other/Flag'
import CustomSelect from 'components/elements/inputs/CustomSelect'
import Loader from 'components/elements/progress/Loader'
import { ReviewG2, ReviewCapterra } from 'components/icons'

import validUsZips from 'mocks/valid-us-zips.json'
import { ServiceActionsTypes } from 'store/service/actions'
import {
  selectLocations,
  selectLocationsFetching,
} from 'store/service/selectors'
import {
  selectBillingModalVisibility,
  selectCouponValidationProcessing,
  selectAppliedCouponInfo,
  selectIsCouponInvalid,
  selectSubscriptionProcessing,
} from 'store/pricing/selectors'
import { PricingActionsTypes } from 'store/pricing/actions'
import { IBillingInfoData, ISubscriptionPlan } from 'models/pricing'
import { selectUserFirstName, selectUserLastName } from 'store/user/selectors'

interface IBillingFormModal {
  onModalClose: () => void
  selectedPlan: ISubscriptionPlan
  onSubmitBillingForm: (data: IBillingInfoData) => void
}

const BillingFormModal: React.FC<IBillingFormModal> = (props) => {
  const { onSubmitBillingForm, selectedPlan, onModalClose } = props

  const dispatch = useDispatch()

  const savedFirstName = useSelector(selectUserFirstName)
  const savedLastName = useSelector(selectUserLastName)
  const availableLocations = useSelector(selectLocations)
  const locationsFetching = useSelector(selectLocationsFetching)
  const isBillingModalShown = useSelector(selectBillingModalVisibility)
  const couponValidationProcessing = useSelector(
    selectCouponValidationProcessing
  )
  const appliedCouponInfo = useSelector(selectAppliedCouponInfo)
  const isCouponInvalid = useSelector(selectIsCouponInvalid)
  const subscriptionProcessing = useSelector(selectSubscriptionProcessing)

  const [amount, setAmount] = useState('')
  const [firstName, setFirstName] = useState(savedFirstName || '')
  const [lastName, setLastName] = useState(savedLastName || '')
  const [selectedCountry, setCountry] = useState<ISelectOption | null>(null)
  const [zipCode, setZipCode] = useState('')
  const [isAccordionActive, toggleAccordion] = useState(false)
  const [couponString, setCouponString] = useState('')

  useEffect(() => {
    if (isBillingModalShown) {
      dispatch({ type: ServiceActionsTypes.SERVICE_GET_LOCATIONS })
    }
  }, [isBillingModalShown, dispatch])

  const handleModalClose = () => {
    onModalClose()
  }

  const getCountriesOptions = useCallback(() => {
    const options: ISelectOption[] = []

    if (availableLocations) {
      Object.values(availableLocations).forEach((location: any) => {
        options.push({
          label: location.name,
          value: location.code,
        })
      })
    }

    return options
  }, [availableLocations])

  const countriesOptions = useMemo(
    () => getCountriesOptions(),
    [getCountriesOptions]
  )

  const handleCountryChange = (option: any) => {
    setCountry(option)

    dispatch({
      type: ServiceActionsTypes.SERVICE_GET_LOCATION_TARGETS,
      data: { country: option.value },
    })
  }

  const isZipCodeValid = () => {
    if (!!selectedCountry && !!zipCode) {
      if (selectedCountry.value.toUpperCase() === 'US') {
        return validUsZips.indexOf(zipCode) >= 0
      } else if (postcodeValidatorExistsForCountry(selectedCountry.value.toUpperCase())) {
        return postcodeValidator(zipCode, selectedCountry.value.toUpperCase())
      } else {
        return true
      }
    } else {
      return true
    }
  }

  const handleCouponChange = (e: any) => {
    setCouponString(e.target.value)

    if (isCouponInvalid) {
      dispatch({
        type: PricingActionsTypes.PRICING_SET_COUPON_VALIDATION_ERROR,
        isCouponInvalid: false,
      })
    }
  }

  const handleCouponValidate = () => {
    dispatch({
      type: PricingActionsTypes.PRICING_VALIDATE_COUPON,
      coupon: couponString,
    })
  }

  const handleFormSubmit = () => {
    let billingData: IBillingInfoData = {
      plan_id: selectedPlan?.id,
      country: selectedCountry?.value,
      name: `${firstName} ${lastName}`,
      zip_code: zipCode,
    }

    if (!!appliedCouponInfo) {
      billingData.coupon = couponString
    }

    if (selectedPlan?.type === 'payg') {
      billingData.amount = amount
    }

    onSubmitBillingForm(billingData)
  }

  const amountInnerNotice = +amount > 0 ? `Total: $${amount} (+VAT)` : ''

  const iconedCountriesOptions = countriesOptions.map((item) => ({
    ...item,
    icon:
      item.value === 'any' ? null : (
        <Flag countryCode={item.value} className={styles.optionFlag} />
      ),
  }))

  const zipCodeLabel = !isZipCodeValid() ? 'ZIP - incorrect' : 'ZIP'

  const renderCouponUi = () => {
    if (!appliedCouponInfo) {
      return (
        <div
          className={`
        ${styles.accordion}
        ${isAccordionActive ? styles.active : ''}
      `}
        >
          <button
            className={`
                ${styles.accordionToggle}
                ${isAccordionActive ? styles.active : ''}
              `}
            onClick={() => toggleAccordion(!isAccordionActive)}
          >
            Add coupon
          </button>

          <div
            className={`
                ${styles.accordionContent}
                ${isAccordionActive ? styles.active : ''}
              `}
          >
            <div className={styles.accordionMain}>
              <CustomInput
                inputClassName={styles.couponInput}
                value={couponString}
                onChange={handleCouponChange}
                readOnly={couponValidationProcessing}
                error={isCouponInvalid}
              />

              {couponString.length > 0 && (
                <CustomButton
                  color={'ghost'}
                  className={styles.couponBtn}
                  onClick={handleCouponValidate}
                  loading={couponValidationProcessing}
                  disabled={isCouponInvalid}
                >
                  Apply
                </CustomButton>
              )}
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div className={styles.appliedCouponInfo}>
          Coupon applied: -{appliedCouponInfo.discount * 100}%
        </div>
      )
    }
  }

  return (
    <CustomModal
      title={'Billing info'}
      isModalOpen={isBillingModalShown}
      shouldCloseOnOverlayClick
      showCross
      modalWidth={'560px'}
      onModalClose={handleModalClose}
    >
      {locationsFetching ? (
        <div className={styles.loaderContainer}>
          <Loader />
        </div>
      ) : (
        <>
          {selectedPlan?.type === 'payg' && (
            <InputGroup
              labelTop={'USD amount to start (15 USD minimum)'}
              className={styles.group}
            >
              <CustomInput
                innerNotice={amountInnerNotice}
                value={amount}
                type={'number'}
                // error={+amount < 15}
                onChange={(e) => setAmount(e.target.value)}
              />
            </InputGroup>
          )}

          <InputGroup labelTop={'First name'} className={styles.group}>
            <CustomInput
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              readOnly={subscriptionProcessing}
            />
          </InputGroup>

          <InputGroup labelTop={'Last name'} className={styles.group}>
            <CustomInput
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              readOnly={subscriptionProcessing}
            />
          </InputGroup>

          <div className={styles.row}>
            <InputGroup labelTop={'Country'} className={styles.group}>
              <CustomSelect
                onChange={handleCountryChange}
                value={selectedCountry}
                name={'billing-info-country'}
                options={iconedCountriesOptions}
                placeholder={'Select country'}
                isSearchable={true}
                disabled={subscriptionProcessing}
              />
            </InputGroup>

            <InputGroup
              labelTop={zipCodeLabel}
              className={styles.zipGroup}
              isErrored={!isZipCodeValid()}
            >
              <CustomInput
                value={zipCode}
                onChange={(e) => setZipCode(e.target.value)}
                readOnly={subscriptionProcessing}
                error={!isZipCodeValid()}
              />
            </InputGroup>
          </div>

          {renderCouponUi()}

          <CustomButton
            wide
            className={styles.submitBtn}
            color={'blue'}
            onClick={handleFormSubmit}
            disabled={
              (selectedPlan?.type === 'payg' && +amount < 15) ||
              !firstName ||
              !lastName ||
              !selectedCountry ||
              !zipCode ||
              !isZipCodeValid()
            }
            loading={subscriptionProcessing}
          >
            Next
          </CustomButton>

          <div className={styles.reviews}>
            <div className={styles.reviewItem}>
              <ReviewG2 />
            </div>
            <div className={styles.reviewItem}>
              <ReviewCapterra />
            </div>
          </div>
        </>
      )}
    </CustomModal>
  )
}

export default BillingFormModal

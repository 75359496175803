const name = 'serviceReducer'

export const selectLocations = (state: any) => state[name].locations
export const selectLocationsFetching = (state: any) =>
  state[name].locationsFetching
export const selectLocationTargets = (state: any) => state[name].locationTargets
export const selectLocationTargetsFetching = (state: any) =>
  state[name].locationTargetsFetching
export const selectMaintenanceModeStatus = (state: any) =>
  state[name].isMaintenanceMode
